<script>
import PageHeader from "@/components/page-header";
import FormPermissions from "@/views/pages/permission/form.vue"
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { ApiClientSelectedPlatform } from "@/common/http-common"
export default {
  components: {
    PageHeader, FormPermissions
  },
  data() {
    return {
      title: this.$t('permissions.update'),
      item:{
        title:""
      },
      items: []
    };
  },
  methods: {
    updateItem(data) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).put("/v1/admin/domains/item/permissions/item?itemId="+rs.itemId, data).then(function () {
        if (self.$route.query.data) {
          var rs = JSON.parse(atob(self.$route.query.data))
          if (rs.items) {
            self.$router.replace({ path: rs.items[rs.items.length - 2].path, query: { data: rs.items[rs.items.length - 2].data } }).catch(() => { });
          }
        }
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    getPermissionsAvailable() {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/domains/item/permissions/available").then(function (response) {
        self.getPermission(response.data.items)
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    getPermission(items) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/domains/item/permissions/item?itemId=" + rs.itemId).then(function (response) {
        self.item = response.data.item
        for (const item of items) {
            for (const permission of item.permissions) {
              permission.value.selected = false
            }
          }

        for (const item of items) {
            for (const permission of item.permissions) {
              for (const j of response.data.item.permissions){
                if (j.permission.type == permission.value.type){
                  permission.value.selected = j.permission.selected 
                }
              }
            }
          }
        self.items = items
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
        BredcrumpManagerEncode(self, { path: self.$route.path, title: 'permissions.update' })
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    }
  },
  mounted() {
    this.getPermissionsAvailable()
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12" style="padding: 0!important;">
      <PageHeader :title="title" />
    </div>
    <div class="col-xl-12"  style="padding: 0!important;">
      <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('permissions.update') }}</h4>
              <p class="card-title-desc">
              </p>
              <FormPermissions :item="item" :items="items" :type="'UPDATE'"
                  @data="updateItem">
                </FormPermissions>
            </div>
          </div>
    </div>
  </div>
</template>